import React, { useState, useLayoutEffect } from "react"
import { graphql } from "gatsby"
import {
  ArticleDate,
} from "../../styledComponents/article"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  Section,
  SectionHeading,
} from "../../styledComponents/section"
import { BackgroundColourBlock } from "../../styledComponents/overView"
import {
  StyledVideoTestimonialArticle,
  StyledVideoTestimonialHeader,
  StyledVideoTestimonialVideo,
  StyledVideoTestimonialLede
} from "../../styledComponents/videoTestimonial"
import EventList from "../../components/EventList"
import ShareTools from "../../components/socialLinks"
import { StyledShareSocialTools } from "../../components/Products/StyledPrimaryProductCard"


const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const PianoBeforeAterQt = ({ data, location }) => {
  

  const pageUrl = `https://${process.env.GATSBY_DOMAIN_NAME}/quantum-touch/an-interview-with-richard-gordon-founder-of-quantum-touch`

  return (
    <Layout displayNavBorder location={location} hideNewsletterSignUp>
      <SEO 
      title="Quantum-Touch and Music"
      description="Karina Grant, Quantum-Touch Instructor & Ambassador "
      url="/quantum-touch/piano-before-after-quantum-touch/"
      />
      <StyledVideoTestimonialArticle itemScope itemType="http://schema.org/Article">
        <StyledVideoTestimonialHeader>
          <h1 itemProp="headline">Quantum-Touch and music</h1>
          <ArticleDate offScreen dateTime=''>Last updated on October 20th 2023</ArticleDate>
        </StyledVideoTestimonialHeader>
        <StyledVideoTestimonialLede>Our experiment 
Henri and I (Henri is also a QT instructor) sent energy to Derek as he played. I videoed Derek playing before we sent him energy and also whilst we were sending him QT.</StyledVideoTestimonialLede>
<StyledVideoTestimonialLede>The difference in the reach and depth of the sound was very clearly audible. His choice of songs changed as soon as we started sending the energy. </StyledVideoTestimonialLede>
<StyledVideoTestimonialLede>Derek is naturally a very present and conscious musician, however his vibration increased even more as the energy swirled towards him. Each note became hypnotic and we could have sat there for hours listening to him. </StyledVideoTestimonialLede>
<StyledVideoTestimonialLede>Watch the short videos below to listen out for the differences in the sound.</StyledVideoTestimonialLede>
<StyledVideoTestimonialLede>If you are are a musician and/or a singer and would like to try this QT experiment then please do get in touch!.</StyledVideoTestimonialLede>
        <StyledVideoTestimonialVideo>
        <h3>Watch before sending Quantum-Touch</h3>
        <figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio"><div class="wp-block-embed__wrapper"><iframe loading="lazy" title="" width="640" height="360" src="https://karinagrant.s3.eu-west-1.amazonaws.com/videos/mexico/piano-before-qt.mp4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe></div></figure>
        
        <h3>Watch after sending Quantum-Touch</h3>
        <figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio loading" ><div class="wp-block-embed__wrapper"><iframe loading="lazy" title="" width="640" height="360" src="https://karinagrant.s3.eu-west-1.amazonaws.com/videos/mexico/piano-after-qt.mp4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe></div></figure>
        
        </StyledVideoTestimonialVideo>
        

      <StyledShareSocialTools type="video">
        {}
      <ShareTools shareTitle="Share this on" pageLocation={pageUrl} postTitle='An interview with Richard Gordon the founder of Quantum-Touch' />
      </StyledShareSocialTools>
        
      </StyledVideoTestimonialArticle>

      
    </Layout>
  )
}

export default PianoBeforeAterQt

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    wpPage(slug: { eq: "homepage" }) {
      title
      slug
      content
      testimonials {
        blockquote {
          citeLocation
          citationName
          quote
        }
        testimonialHeading
        testimonialLede
      }
    }

    aboutKarina: wpPage(slug: { eq: "about" }) {
      aboutKarina {
        aboutKarina
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
      

    allWpPost(sort: { fields: [date], order: DESC } limit: 5) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        isSticky
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }


    allWpProduct(limit: 3) {
      edges {
        node {
          title
          uri
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            hidesale
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
}
`
